@import 'styles/variables';

#ds-currency-dropdown {
    .btn {
        align-items: center;
        border-left: 2px solid $transparent;
        border-right: 2px solid $transparent;
        border-top: unset;
        border-bottom: unset;
        border-radius: 0;
        white-space: nowrap;
        width: 96px;
        padding: 1.3rem 3.05rem 1.3rem 1rem;
        transition: all .15s ease-in-out;
        
        @media (max-width: $extrascreen) {
            padding: 1.3rem 1.35rem 1.3rem 1rem;
        }
    }

    &:hover {
        color: $ds-home-button !important;
        border-color: $transparent;
    }

    &:focus {
        box-shadow: none !important;
        outline: none !important;

    }

    .alt-customer-dropdown-icon-down,
    .alt-customer-dropdown-icon-up {
        width: 13px;
        line-height: 100%;
        vertical-align: middle;
        margin-left: 8px;
        top: 28.2px;
        transition: all .15s ease-in-out;
    }

    .alt-customer-dropdown-icon-up {
        visibility: hidden;
        opacity: 0;
    }

    .show > .alt-customer-dropdown-icon-down {
        visibility: hidden;
        opacity: 0;
    }

    .show > .alt-customer-dropdown-icon-up {
        visibility: visible;
        opacity: 1;
        filter: brightness(0) saturate(100%) invert(47%) sepia(35%) saturate(6945%) hue-rotate(220deg) brightness(92%) contrast(106%);
    }

    .show {
        color: $sign-up-blue !important;
    }

    .dropdown-item:hover {
        background-position: 0;
    }

    .dropdown-item::before {
        content: "";
        position: absolute;
        z-index: 2;
        left: 0;
        bottom: 0;
        height: 0;
    }

    .dropdown-item::before {
        width: 0;
        border-left: 2px solid $white;
        transition: height .1s .1s ease;
    }

    .dropdown-item:hover::before {
        height: 100%;
    }

    .dropdown:hover > ul {
        display: block !important;
    }

    .dropdown:hover > button {
        color: $sign-up-blue !important;
    }

    .dropdown:hover > button > .alt-customer-dropdown-icon-down {
        visibility: hidden;
        opacity: 0;
    }

    .dropdown:hover > button > .alt-customer-dropdown-icon-up {
        visibility: visible;
        opacity: 1;
        filter: brightness(0) saturate(100%) invert(47%) sepia(35%) saturate(6945%) hue-rotate(220deg) brightness(92%) contrast(106%);
    }

    .dropdown-menu {
        background-color: $dropdownprimarybackground;
        border: 0;
        padding: 12px;
        margin-top: 0;
    }

    .dropdown-item {
        color: $gray;
        margin-left: 0;
        padding: 12px;
        background-color: $dropdownprimarybackground;
        background-size: 201% 100%;
        transition: background-position .2s;
        max-height: 45px;
    }

    .dropdown-item:active {
        background-color: $dropdownprimarybackground;
    }
}
