@import 'styles/variables';

#ds-customer-portal-navigation {
    display: inline-block;
    height: 100%;
    max-width: 296px;
    position: relative;
    width: 100%;

    @media (max-width: $widescreen) {
        min-height: unset;
        max-width: unset;
    }

    .main-progress-bar {
        display: block;

        @media (max-width: $widescreen) {
            display: none;
        }
    }

    .icons {
        padding-right: 1em;
        width: 50px;
    }

    .navigation-pages {
        background: $white;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        padding: 15px 15px;
    }

    .nav-icon {
        color: $medium-gray !important;
    }

    ul {
        list-style: none;
        padding-left: 0;

        .link {
            color: $darkergray;
            display: flex;
            padding: 14px 0 14px 15px;
            position: relative;
            transition: 0.1s all;
        }

        .link:hover {
            cursor: pointer;
            box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 8%);

            .purple-border {
                @extend .border-active;
            }

            .nav-icon {
                color: $ds-footer-link !important;
            }

            .material-icons {
                color: $ds-footer-link !important;
            }
        }
    }

    .active {
        background: $white !important;
        color: $black !important;
        font-weight: 500;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);

        .icon,
        .material-icons,
        .nav-icon {
            color: $ds-footer-link !important;
        }
        
        .link {
            color: $black;
        }
    }

    .v-icon {
        font-size: 18px;
    }

    .purple-border {
        position: absolute;
        background: $ds-footer-link;
        left: 0;
        bottom: 0;
        right: 0;
        width: 2px;
        height: 0;
        transition: height .1s ease;
    }

    .border-active {
        position: absolute;
        display: block;
        background: $ds-footer-link;
        top: 0;
        right: 0;
        height: 52px;
    }

    .portal-image {
        width: 20px;
    }

    .icon {
        margin-right: 15px;
    }

    .verification-categories {
        position: relative;

        .verification-status {
            font-size: $regular;
            letter-spacing: 0.92px;
        }

        .verification-list {
            background: $secondarybackground;
            border: 1px solid $primaryborder;
            border-radius: 4px;

            .verification-category {
                display: flex;
                padding: 20px;
                
                @media (max-width: $tablet) {
                    height: 57px;
                }
            }

            .verification-category:not(:last-child) {
                border-bottom: 1px solid $primaryborder;
            }
        }

        .verification-icon {
            position: absolute;
            right: 15px;
            width: 20px;
        }
    }

    .in-review {
        background: rgba(254, 215, 102, 0.2);
        color: $yellow;

        .verify-icon {
            filter: brightness(0) saturate(100%) invert(87%) sepia(51%) saturate(4664%) hue-rotate(336deg) brightness(100%) contrast(98%);
        }
    }

    .not-verified {
        background: rgba(253, 74, 74, 0.05);
        color: $red;

        .verify-icon {
            filter: brightness(0) saturate(100%) invert(43%) sepia(51%) saturate(4664%) hue-rotate(336deg) brightness(108%) contrast(98%);
        }
    }

    .verified {
        background: rgba(57, 226, 157, 0.1);

        span {
            color: $green;
        }
    }

    @media (max-width: $widescreen) {
        .navigation-pages {
            border-top: 1px solid $primaryborder !important;
            border: unset;
            bottom: 0;
            left: 0;
            position: fixed;
            width: 100%;

            ul {
                flex-direction: row !important;
                margin-bottom: 0;

                li {
                    width: 25%;
                }
            }

            .link {
                align-items: center;
                border: unset;
                border-radius: unset;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                padding-left: 0;
                text-align: center;
                padding: 15px 0 15px 0;

                .purple-border.border-active {
                    bottom: 0;
                    border-radius: unset;
                    top: unset;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 4px;
                }
            }
        }
    }

    @media (max-width: $phone) {        
        section[data-scrollbar] {
            max-height: 200px;
        }

        .verification-categories .verification-list .verification-category {
            padding: 17px;
        }
    }
}
