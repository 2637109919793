@import 'styles/variables';

#ds-cart-login {
    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: $desktop) {
        padding-left: 8px;
        padding-right: 8px;
    }

    .checkbox-mobile {
        padding-left: 0.1rem;
    }

    .login-input-field {
        max-width: 400px;
        margin-top: 10px;

        @media (max-width: $desktop) {
            max-width: unset;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    .password-input-field {
        @media (max-width: $tablet) {
            max-width: 100%;
        }
    }

    .white-x-mark {
        position: absolute;
        right: 1.25rem;
        top: 1.25rem;
        width: 10px;
    }

    .circle-icon-email,
    .circle-icon-password {
        position: absolute;
        right: 1rem;
        top: 1rem;
        height: 16px;
        width: 16px;
    }

    .mini-spinner-icon {
        position: absolute;
        top: 12px;
        right: 12px;
    }

    .link-click:hover {
        text-decoration: underline;
    }

    .subtext {
        font-size: $regular;
        right: 0;
        top: 54px;
        width: fit-content;
    }

    .user-form {
        margin-top: 37px;

        @media (max-width: $desktop) {
            margin-top: 26px;
        }
    }

    .text-form-email {
        margin-top: 27px;
        line-height: 20px;

        @media (max-width: $desktop) {
            margin-top: 23px;
        }
    }

    .email-input-label {
        margin-top: 37px;
    }

    .text-legal-pages {
        margin-top: 10px;
    }

    .token-form {
        margin-top: 40px;

        @media (max-width: $desktop) {
            margin-top: 20px;
        }
    }

    .top-text-2fa {
        margin-top: 16px;
    }

    .mdc-text-field__input {
        padding-right: 30px;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill::first-line {
        transition: background-color 5000s ease-in-out 0s;
        font-family: $ds-home !important;
        font-size: 1rem !important;
        z-index: inherit;
    }
    .ds-input--error {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            transition: background-color 5000s ease-in-out 0s;
        }
    }
    .ds-input--warning {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            transition: background-color 5000s ease-in-out 0s;
        }
    }
    .ds-input--success {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            transition: background-color 5000s ease-in-out 0s;
        }
    }
}
