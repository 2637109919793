@import 'styles/variables';

#ds-coupon-input {
    border-radius: 4px;

    .coupon-form {
        --bs-form-file-height: calc(1.5em + 0.75rem + 18px) !important;
        padding: 0 0 3px 0;
        position: relative;

        .circle-icon {
            position: absolute;
            right: 10px;
            top: 16px;
            z-index: 1;
            height: 16px;
            width: 16px;
        }

        .x-icon {
            position: absolute;
            right: 12px;
            top: 20px;
            z-index: 1;
            height: 10px;
            width: 10px;
        }

        .mini-spinner-icon {
            position: absolute;
            top: 12px;
            right: 6px;
        }
    }
}
