@use '~@material/button';
@use '~@material/theme';
@import 'styles/variables';

#ds-button {
    --mdc-typography-button-letter-spacing: normal;

    .primary {
        @include button.ink-color($white);
        @include button.height(auto);
    }

    .primary-style {
        background-color: rgba(87, 80, 255, 1);

        &:hover {
            background-color: rgba(113, 118, 255, 1);
        }

        &:active {
            background-color: rgba(153, 166, 255, 1)
        }
    }

    .primary-purple {
        background: $sign-up-blue;

        &:hover {
            background: rgba(59, 67, 161, 1);
        }
    }

    .primary-disabled {
        background: linear-gradient(90.12deg,
                rgba(44, 0, 111, 0.4) 2.45%,
                rgba(0, 46, 128, 0.4) 101.9%) !important;
        pointer-events: none !important;
        cursor: unset !important;
    }

    .primary-inverted {
        @include button.container-fill-color($white);
        @include button.ink-color($purple-text);

        &:hover {
            @include button.container-fill-color(rgba(255, 255, 255, 0.9));
        }
    }

    .primary-outlined {
        @include button.container-fill-color(transparent);
        @include button.ink-color($ds-purple);
        @include button.height(auto);
        @include button.horizontal-padding(48px);
        @include button.height(50px);
        border: 2px solid $ds-purple !important;
        border-radius: 12px !important;

        &:hover {
            @include button.ink-color(rgba(67, 0, 169, 0.8));
            border: 2px solid rgba(67, 0, 169, 0.8) !important;
        }
    }

    .purple-outlined {
        @include button.container-fill-color(transparent);
        @include button.ink-color($sign-up-blue);
        @include button.height(auto);
        @include button.horizontal-padding(48px);
        @include button.height(50px);
        border: 2px solid $sign-up-blue !important;
        border-radius: 4px !important;

        &:hover {
            @include button.ink-color(rgba(67, 0, 169, 0.8));
            border: 2px solid rgba(67, 0, 169, 0.8) !important;
        }
    }

    .secondary {
        @include button.container-fill-color(rgba(239, 239, 253, 1));
        @include button.ink-color($ds-purple);
        @include button.horizontal-padding(1.5rem);
        @include button.height(3rem);

        &:hover {
            @include button.container-fill-color(rgba(189, 189, 221, 0.6));
        }
    }

    .secondary-blue {
        @include button.container-fill-color(rgba(239, 239, 253, 1));
        @include button.ink-color($ds-home-button);
        @include button.horizontal-padding(1.5rem);
        @include button.height(3rem);

        &:hover {
            @include button.container-fill-color(rgba(189, 189, 221, 0.6));
        }
    }

    .danger {
        @include button.container-fill-color($off-red);
        @include button.ink-color($red);
        @include button.horizontal-padding(24px);
        @include button.height(50px);

        &:hover {
            @include button.container-fill-color(rgba(255, 15, 15, 0.3));
        }
    }

    .lilac {
        @include button.container-fill-color($lilac);
        @include button.ink-color($white);
        @include button.height(40px);

        &:hover {
            @include button.container-fill-color(rgba(87, 98, 213, 0.9));
        }
    }

    .offlilac {
        @include button.container-fill-color($offlilac);
        @include button.ink-color($offblack);
        @include button.height(50px);

        &:hover {
            @include button.container-fill-color(rgba(87, 98, 213, 0.3));
        }
    }

    .purple-background {
        background: linear-gradient(90.12deg, #4300a9 2.45%, #00338c 101.9%) !important;
        @include button.container-fill-color($transparent);
        @include button.ink-color($darkergray);
        @include button.horizontal-padding(4.5rem);
        @include button.height(50px);
    }

    .ds-button-background {
        background: $sign-up-blue !important;
        @include button.container-fill-color($transparent);
        @include button.ink-color($darkergray);
        @include button.horizontal-padding(2.7rem);
        @include button.height(50px);
    }
    
    .material-icons {
        margin-top: 1px;
    }
}
