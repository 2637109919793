@import 'styles/variables';

#ds-checkbox-filter {
    .option {
        &:hover {
            .option-text {
                color: $lightpurple !important;
            }

            .bullet {
                background-color:$lightpurplehover;
            }
        }
    }

    .option-selected {
        color: $lightpurple !important;
    }

    .option-text {
        color: $midgray;
    }

    .select-arrow {
        color: $midgray;
    }

    .select-box {
        width: 25px;
        height: 25px;
        border-radius: 3px;
        margin-right: 10px;
        background-color: $inputgray;
    }

    .select-box-dropdown {
        width: 160px;
        height: 50px;
        background-color: $white;
        border: 1px solid $select-gray;
        border-radius: 4px;
        padding: 0 19px;
        color: $midgray;
        cursor: pointer;
        text-transform: capitalize;

        @media (max-width: $phone) {
            font-size: $small;
            width: 120px;
        }
    }

    .bullet {
        height: 15px;
        width: 15px;
        border-radius: 4px;
        background-color: $inputgray;
        margin-right: 8px;

        &:hover {
            background-color: $lightpurplehover;

            .option {
                color: $lightpurple !important;
            }
        }
    }

    .bullet-active {
        background: $lightpurple
    }

    .select-box-container {
        display: none;
        min-height: 136px;
        width: 160px;
        border-radius: 4px;
        background-color: $white;
        border: 1px solid $palegray;
        z-index: 99;
    }

    .checkbox-container {
        padding: 15px 12px;
    }

    .dropdown-container {
        padding: 7px 5px 15px 5px;
    }

    .select-spacer {
        transform: translateY(5px);
        padding-bottom: 10px;
    }

    .select-arrow {
        transition: all ease .2s;
    }
}
