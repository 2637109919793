@use '@material/select/_index' as select;
@import 'styles/variables';
@import 'styles/mixins';

#ds-cart-product {
    .product-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: $desktop) {
            max-width: 201px;
        }
    }

    .product-description {
        p > img,
        img {
            width: 100%;
            height: 100%;
        }
    }

    .white-circle-icon {
        right: 15px;
        top: 20px;
        @include square(12px);
    }

    .mini-spinner-icon {
        right: 8px;
        top: 13px;
    }

    .insurance-icon {
        border-radius: 25px;
        height: 25px;
        right: 6px;
        top: 3px;
        width: 23px;
    }

    .span-insurance {
        height: 12px;
        width: 146px;
        margin-top: 40px;
        margin-bottom: 21px;
    }

    .product-content {
        padding: 18px 14px 18px;

        @media (max-width: $desktop) {
            padding: 0 23px 0 23px;
        }

        @media (max-width: $tablet) {
            padding: 0 25px 0;
        }

        @media (max-width: $phone) {
            padding: 0 23px;
        }

        .icon-box {
            border-radius: 4px;
            height: 70px;
            width: 70px;
            margin-right: 4px;

            img {
                top: 0;
            }
        }

        .details-button {
            color: rgba(254, 215, 102, 0.6);
            text-decoration: none;
        }

        .details-button .material-icons {
            transform: rotate(0deg);
            transition: 0.3s ease;
        }

        .details-button.opened .material-icons {
            transform: rotate(180deg);
        }

        .details-button:hover {
            color: $yellow;
        }
    }

    .character-name-container {
        max-width: 161px;
        margin-left: -4px;

        @media (max-width: $tablet) {
            max-width: unset;
            margin-left: -30px;
            width: 105px;
        }

        @media (max-width: $phone) {
            margin-left: 0;
        }
    }

    .circle-icon {
        height: 16px;
        right: 12px;
        top: 17px;
        width: 16px;
    }

    .character-name.mdc-text-field--disabled {
        padding-right: 0;
    }

    .input-password.character-name:not(.mdc-text-field--disabled) .mdc-text-field__input {
        overflow: hidden;
        padding-right: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:focus {
            padding-right: 21px;

            @media (max-width: $phone) {
                padding-right: 11px;
            }
        }
    }

    ::placeholder {
        color: rgba(114, 118, 125, .75);
    }
    
    ::-ms-input-placeholder {
        color: rgba(114, 118, 125, .75);
    }

    .static-width {
        width: 300px;
    }

    .product-details {
        width: 25%;
    }

    .mdc-floating-label {
        font-size: 14px !important;
        top: 50%;
        transform: translateY(-50%) !important;
    }

    .mdc-select__anchor {
        border-radius: 4px;
    }
    .text-regular-cart{
        font-size: $regular !important;
    }

    @media (max-width: $desktop) {
        .input-password.character-name:not(.mdc-text-field--disabled) .mdc-text-field__input,
        .input-password.character-name.mdc-text-field--disabled .mdc-text-field__input {
            font-size: 12px;
        }

        .character-name {
            padding-left: 10%;
        }

        .circle-icon {
            max-height: 14px;
            right: 8px;
            top: 8px;
        }

        .white-circle-icon {
            right: 8px;
            top: 10px;
        }

        .mini-spinner-icon {
            width: 20px !important;
            height: 20px !important;
            right: 6px;
            top: 6px;
        }

        .character-name {
            max-height: 30px;
        }
    }

    @media (max-width: $tablet) {
        .product-content {
            .icon-box {
                max-width: 100%;
            }
        }

        .circle-icon {
            right: 7px;
            top: 7px;
        }

        .static-width {
            width: 100%;
        }
    }

    @media (max-width: $phone) {
        .character-name {
            max-height: 30px;
        }
    }

    .limit-lines {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .font {
        p {
            font-size: 14px;
        }
    }

    .blue-line-bootoms {
        border-bottom: 2px solid $primaryborder;
    }

    .blue-line-bootom-space {
        border-bottom: 2px solid $primaryborder;
        margin-top: 25px;
    }

    .price-text {
        @media (min-width: $extrascreen) {
            margin-left: 27px; 
            margin-right: 23px;
        } 
    }

    .mdc-list-item {
        &:hover {
            background-color: rgb(233, 236, 239, 0.05);
        }

        .mdc-list-item__primary-text {
            letter-spacing: 0.68px;
            color: rgba(33, 37, 41, 1);
            font-size: inherit;
            font-family: inherit;
        }
    }

    .ds-select {
        @include select.outline-color($transparent);
        background: rgb(243, 245, 247);
        border: none;
        position: relative;
    }

    mdc-select {
        &.mdc-select--activated .faq-arrow-icon {
            transform: rotate(180deg) !important;
        }
    
        .faq-arrow-icon {
            transform: rotate(0deg) !important;
        }
    }

    .gray-border-product {
        @media (min-width: $extrascreen) {
            border-bottom: 1px solid rgba(112, 112, 112, 0.15);
        } 
    }

    .text-left {
        margin-right: 60px;
    }
}
