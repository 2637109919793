@import 'styles/variables';

#ds-cart-disclaimer {
    .disclaimer {
        background-color: rgba(87, 98, 213, 0.05);
        width: 100%;
        padding: 20px 16px 31px 16px;

        @media (max-width: $tablet) {
            padding: 20px 17px 20px 20px;
        }

        @media (max-width: $phone) {
            padding: 15px 9px 15px 19px;
        }
    }

    .disclaimer-blue-border {
        position: absolute;
        background: $ds-home-button;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        border-radius: 4px;
    }

    @media (max-width: $desktop) {
        .disclaimer-letter-spacing {
            letter-spacing: 0.11px;
        }
    }
}
