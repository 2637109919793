@import 'styles/variables';
@import "styles/typography";

#ds-custom-title {
    .input-ticket-title {
        padding-left: 0;
        width: 235px;

        @media (max-width: $tablet) {
            width: 188px;
        }

        & .mdc-text-field__input {
            font-size: $largeplus;

            @media (max-width: $phone) {
                font-size: $medium;
            }
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        box-shadow: 0 0 0 30px white inset !important;
    }

    #title {
        max-width: 849px;
        font-size: $largeplus;

        @media (max-width: $tablet) {
            max-width: 660px;
        }

        @media (max-width: $phone) {
            max-width: 275px;
            font-size: $medium;
        }

        & ~ cg-touch-target span {
            display: none;

            @media (max-width: $tablet) {
                display: inline-block;
            }
        }
    }

    &:hover {
        #title ~ #cg-touch-target span {
            display: inline-block;
        }
    }

    .mdc-list-item__primary-text {
        color: $black !important;
        line-height: 14px;
        letter-spacing: 0.114px;
        @extend .font-regular;
        @extend .text-regular;
    }

    .mdc-list-item:not(:last-child) {
        border-bottom: solid 1px rgba(76, 96, 133, 0.1);
    }

    .mdc-menu {
        padding-left: 15px;

        & > div > div {
            width: 268px;
        }
    }

    .mdc-menu-surface {
        width: 300px !important;
        height: 331px !important;
        box-shadow: 0 0 10px rgb(0 0 0 / 16%);
        border-radius: 4px;

        &.surface-question {
            height: 280px !important;
        }

        h5 {
            line-height: 12px;
            letter-spacing: 0.095px;
            @extend .text-tiny;
            @extend .font-light;
        }

        &::-webkit-scrollbar {
            width: 18px;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            background-color: $purple-text;
            border: 7px solid transparent;
            border-radius: 20px;

            &:hover {
                background-color: $purple-text;
            }

            &:active {
                background-color: $purple-text;
            }
        }

        & .close-icon {
            margin-top: 10px;
            width: min-content;
        }
    }

    & form {
        width: 235px;

        @media (max-width: $tablet) {
            width: 188px;
        }

        & span {
            display: none;

            @media (max-width: $tablet) {
                display: inline-block;
            }
        }

        &:hover {
            & span {
                display: inline-block;
            }
        }
    }

    .question-container {
        width: 268px;
        height: 232px;
        padding-left: 5px;

        & > div {
            width: 243px;
        }

        & .question-title {
            line-height: 14px;
            letter-spacing: 0.114px;
        }

        & .question-answer {
            line-height: 14px;
            letter-spacing: 0.448px;
        }
        &::-webkit-scrollbar {
            width: 18px;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            background-color: $purple-text;
            border: 7px solid transparent;
            border-radius: 20px;

            &:hover {
                background-color: $purple-text;
            }

            &:active {
                background-color: $purple-text;
            }
        }
    }
}
