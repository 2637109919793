@import 'styles/variables';

#ds-chat-message {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin: 20px 0;

    p {
        margin-bottom: 0;
    }

    &.chat-position-right {
        justify-content: flex-end;
    }

    &.chat-position-left {
        justify-content: flex-start;
    }

    .first {
        order: 1;

        & .message {
            background: $lightpurple;
            color: $white;
            padding: 18px 16px 17px 21px;

            @media (max-width: $phone) {
                padding: 11px 15px 10px;
            }

            &.files {
                background: rgba(239, 239, 253, 1) !important;
                color: $purple-text  !important;
            }
        }
    }

    .second {
        order: 2;

        & .message {
            background: rgba(196, 196, 196, 0.1);
            color: $input-placeholder;
            padding: 18px 21px 17px 16px;

            @media (max-width: $phone) {
                padding: 11px 15px 10px;
            }
        }
    }

    img.first {
        justify-self: start;
    }

    img.second {
        justify-self: end;
    }

    img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
    }

    .message {
        min-height: 50px;
        border-radius: 12px;
        margin: 0 9px;

        @media (max-width: $tablet) {
            max-width: 430px;
        }

        @media (max-width: $phone) {
            max-width: 233px;
        }
    }

    .files {
        border-radius: 12px;
        max-height: 104px;
        min-height: 50px;

        & img {
            width: 90px;
            height: 90px;
            border-radius: 12px;
            object-fit: cover;
            object-position: center center;
        }
    }

    .container-files {
        margin-top: 20px;
    }

    .container-image {
        &:not(:last-of-type) {
            margin-right: 9px;
        }
    }

    .extension {
        height: 20px !important;
        width: 20px !important;
        margin-right: 6px;
        border-radius: unset !important;
        object-fit: none !important;
    }

    .images-files {
        padding: 7px !important;
    }

    .date {
        color: rgba(217, 217, 217, 1);

        @media (max-width: $phone) {
            margin: 5px 10px;
        }
    }

    .mask {
        height: 90px;
        width: 90px;
        background-color: $input-placeholder;
        border-radius: 12px;
    }
}
