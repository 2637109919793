@import 'styles/variables';

#ds-deep-dropdown {
    .btn {
        align-items: center;
        border-left: 2px solid $transparent;
        border-top: unset;
        border-bottom: unset;
        border-right: unset;
        white-space: nowrap;
        border-radius: 0;
        white-space: nowrap;
        padding: 1.3rem 3.7rem 1.3rem 1rem;
        transition: all .15s ease-in-out;
    }

    .stick-1 {
        top: 10px;
        right: 5px;
    }

    .stick-2 {
        top: 10px;
        right: -1px;
    }

    .btn:focus {
        box-shadow: none !important;
        outline: none !important;

    }

    .name-width {
        max-width: 100px;
    }

    .show {
        background-color: $dropdownprimarybackground;
        border-left: 2px solid $sign-up-blue;
        color: $white !important;
        img.font-arrow {
            filter: invert(20%) sepia(57%) saturate(550%) hue-rotate(89deg) brightness(91%) contrast(93%);
        }
    }

    .sell-categories {
        min-width: 218px;
    }

    .dropdown-item {
        color: $gray !important;
        cursor: pointer;
        margin-left: 0;
        padding: 12px 0 12px 12px;
        background: $dropdownprimarybackground;
        background-size: 205% 100%;
        transition: background-position .2s;
        max-height: 45.3px;

        .material-icons:not(.submenu-arrow) {
            display: none;
        }
        
        .material-icons.submenu-arrow {
            visibility: hidden;
            opacity: 0;
            transition: .2s all ease;
            display: unset;
        }
    }

    .user-dropdown {
        max-height: unset !important;
    }

    .dropdown-item-submenu {
        background: $dropdownprimarybackground;
        background-size: 201% 100%;
    }

    .dropdown-menu {
        background-color: $dropdownprimarybackground;
        border: 0;
        padding: 12px;
        position: absolute;

        li {
            position: relative;
        }
    }

    .submenu {
        background-color: $dropdownprimarybackground;
        display: none;
        left: 100%;
        position: absolute;
        top: -12px;
    }

    .sell-dropdown-container {
        min-width: 200px !important;
    }

    .user-dropdown-icon {
        top: 32px;
    }

    .arrow-position {
        top: 19px;
        right: 42px;
    }

    .submenu-left {
        left: auto;
        right: 100%;
    }

    .dropdown-item:active {
        background-color: $dropdownprimarybackground;
    }

    .dropdown-item:focus {
        background: $transparent;
    }

    .dropdown-orientation {
        left: auto;
        right: 0;
    }

    .btn-padding {
        padding: 1.3rem 1.7rem 1.3rem .5rem;
    }

    @media (min-width: $desktop) {
        .btn:hover {
            color: $white !important;
            .hover-purple {
                color: $sign-up-blue;
                transition: all .2s ease-in-out;
            }
        }

        .submenu .dropdown-item {
            background: $dropdownprimarybackground;
            background-size: 201% 101%;
        }

        .dropdown:hover > ul {
            display: block !important;
        }

        .dropdown-item:hover {
            background-position: 0;

            .material-icons,
            .material-icons-outlined {
                color: $white;
            }

            .material-icons.submenu-arrow {
                visibility: visible;
                opacity: 1;
            }

            .material-icons {
                display: block;
            }

            .customer-dropdown-img.order-img {
                content: url('/static/icons/arrow-up.svg');
                filter: unset;
            }
        }

        .dropdown-item::before {
            content: "";
            position: absolute;
            z-index: 2;
            left: 0;
            bottom: 0;
            height: 0;
        }

        .dropdown-item::before {
            width: 0;
            border-left: 2px solid $white;
            transition: height .1s .1s ease;
        }

        .dropdown-item:hover::before {
            height: 100%;
        }

        .dropdown-menu > li > .submenu  .dropdown-item:hover {
            background-position: 0;
        }

        .dropdown:hover > button > .alt-customer-dropdown-icon-down {
            visibility: hidden;
            opacity: 0;
        }

        .dropdown:hover > button > .alt-customer-dropdown-icon-up {
            visibility: visible;
            opacity: 1;
        }

        .dropdown:hover > button {
            background-color: $dropdownprimarybackground;
            border-left: 2px solid $ds-home-button;
            color: $ds-home-button !important;
        }

        .dropdown:hover > button > .arrow-position > .arrow-container-down {
            visibility: hidden;
            opacity: 0;
        }
    
        .dropdown:hover > button > .arrow-position > .arrow-container-up {
            visibility: visible;
            opacity: 1;
            filter: brightness(0) saturate(100%) invert(47%) sepia(35%) saturate(6945%) hue-rotate(220deg) brightness(92%) contrast(106%);
        }
    }

    @media (max-width: $desktop) {
        .dropdown-item:hover {
            background-color: unset;
        }
    }

    @media (max-width: $phone) {
        .dropdown-menu:last-child {
            left: auto;
            right: 0;
        }
    }

    section[data-simplebar] {
        min-width: 242px;
        max-height: 360px;
        overflow-x: hidden;
        height: unset;
    }
}
